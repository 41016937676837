
import Vue from "vue";

export default Vue.extend({
  name: "App",
  data() {
    return {
      openInWindow: false,
      intern: false,
      applications: [
        {
          title: "Digiwab",
          status: true,
          links: new Map([
            ["Beispiel 1", "/digiwab/9695"],
            ["Beispiel 2", "/digiwab/16315"],
            ["Beispiel 3", "/digiwab/260972"],
            ["Beispiel 4", "/digiwab/6255"],
            ["Beispiel 5", "/digiwab/388908"]
          ])
        },
        {
          title: "Chemie",
          status: true,
          links: new Map([
            ["Beispiel 1", "/chemie/2678515500"],
            ["Beispiel 2", "/chemie/2587541500"]
          ])
        },
        {
          title: "Grimkat",
          status: true,
          links: new Map([["Beispiel 1", "/grimkat/2716230000"]])
        },
        {
          title: "Wasserstand",
          status: true,
          links: new Map([
            ["Beispiel 1", "/wasserstand/2375030600"],
            ["Beispiel 2", "/wasserstand/2718010800"]
          ])
        },
        {
          title: "GUS",
          status: true,
          links: new Map([
            ["Beispiel 1", "/gus/2391566500"],
            ["Beispiel 2", "/gus/2511510500"],
            ["Beispiel 3", "/gus/2549523210"],
            ["Beispiel 4", "/gus/2589535410"],
            ["Beispiel 5", "/gus/2619521210"],
            ["Beispiel 6", "/gus/2649525000"],
            ["Beispiel 7", "/gus/2691510700"]
          ])
        },
        {
          title: "GUS-RUST",
          status: true,
          links: new Map([["Beispiel", "/gus-rust/"]])
        },
        {
          title: "Biologie",
          status: true,
          links: new Map([
            ["Beispiel 1", "/biologie/2714893900"],
            ["Beispiel 2", "/biologie/2541899100"],
            ["Beispiel 3", "/biologie/2397891200"],
            ["Beispiel 4", "/biologie/2714894100"]
          ])
        },
        {
          title: "Grundwasser",
          status: true,
          links: new Map([
            ["Beispiel 1", "/grundwasser/2718210000"],
            ["Beispiel 2", "/grundwasser/2536200200"],
            ["Beispiel 3", "/grundwasser/2548250300"],
            ["Beispiel 4", "/grundwasser/2536170200"],
            ["Beispiel 5", "/grundwasser/2373100600"],
            ["Beispiel 6", "/grundwasser/2673105700"],
            ["Beispiel 7", "/grundwasser/2542250100"]
          ])
        },
        {
          title: "Seenatlas",
          status: true,
          links: new Map([
            ["Beispiel 1", "/seenatlas/26"],
            ["Beispiel 1", "/seenatlas/81"]
          ])
        },
        {
          title: "Seenliste",
          status: true,
          links: new Map([["Beispiel 1", "/seenatlas-seenliste/"]])
        },
        {
          title: "TWIST",
          status: true,
          links: new Map([
            ["Beispiel 1", "/twist/2718695400"],
            ["Beispiel 2", "/twist/2546695072"]
          ])
        },
        {
          title: "IKSMS",
          status: true,
          links: new Map([["Beispiel 1", "/iksms/26500100"]])
        },
        {
          title: "WRRL-RP",
          status: true,
          links: new Map([
            [
              "Fließgewässer 1",
              "/wrrl/fliessgewaesser/2724000000_1?bbox=174411.13926380425,5399317.4,565383.8607361957,5678828.6"
            ],
            [
              "Fließgewässer 2",
              "/wrrl/fliessgewaesser/2375420000_2?bbox=174411.13926380425,5399317.4,565383.8607361957,5678828.6"
            ],
            [
              "Fließgewässer 3",
              "/wrrl/fliessgewaesser/2694800000_0?bbox=174411.13926380425,5399317.4,565383.8607361957,5678828.6"
            ],
            ["Grundwasser Menge 1", "/wrrl/grundwasser-menge/DERP_79"],
            ["Grundwasser Menge 2", "/wrrl/grundwasser-menge/DERP_81"],
            ["Grundwasser Chemie 1", "/wrrl/grundwasser-chemie/DERP_79"],
            ["Grundwasser Chemie 2", "/wrrl/grundwasser-chemie/DERP_81"]
          ])
        },
        {
          title: "Maßnahme",
          status: true,
          links: new Map([
            ["Beispiel 1", "/massnahme/285004"],
            ["Beispiel 2", "/massnahme/1741"]
          ])
        },
        {
          title: "Hochwassermarken",
          status: true,
          links: new Map([
            ["Beispiel 1", "/hwm/1"],
            ["Beispiel 2", "/hwm/6"]
          ])
        },
        {
          title: "Sturzflutgefahrenkarte",
          status: true,
          links: new Map([
            ["Beispiel 1", "/sfgk/1"],
            ["Beispiel 2", "/sfgk/6"]
          ])
        }
      ]
    };
  },
  methods: {
    getIcon(appstatus: boolean): string {
      return appstatus ? "mdi-check" : "mdi-progress-wrench";
    },
    getIconColor(appstatus: boolean): string {
      return appstatus ? "success" : "warning";
    },
    getLink(item: string[]) {
      return item[1] + (this.intern ? "?intern=true" : "");
    },
    openWindow: function(link: string, title: string) {
      const popupWidth = 960;
      const popupHeight = 679;

      const left = (screen.width - popupWidth) / 2;
      const top = (screen.height - popupHeight) / 4;
      if (this.openInWindow)
        window.open(
          link,
          title,
          "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no," +
            "width=" +
            popupWidth +
            ", height=" +
            popupHeight +
            ", top=" +
            top +
            ", left=" +
            left
        );
      else window.location.href = link;
    }
  }
});
